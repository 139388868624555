import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import getProjectConfig from './configs/projectConfigs';



const projectConfig = getProjectConfig();

if (projectConfig) {
  document.title = projectConfig.title;

  const icon = document.getElementById('icon');
  if (icon) {
    icon.href = projectConfig.logo;
  }
} else {
  document.title = 'React App';
  const icon = document.getElementById('icon');
  if (icon) {
    icon.href = '../public/favicon.ico';
  }
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
