import { Box, Drawer, List, Stack, Toolbar, Typography } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React, { useState } from "react";
import { TbIndentDecrease } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import separator from "../../assets/images/Separator-Gradient.png";
import sizeConfigs from "../../configs/sizeConfigs";
import { setOpenDrawer } from "../../redux/features/appStateSlice";
import { appRoutes } from "../../routes/AppRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import ChartIcon from "../../assets/svg/Chart"
import { IoLogOut } from "react-icons/io5";
import WhiteButton from "./Buttons/WhiteButton";
import BlueButton from "./Buttons/BlueButton";
import supabase from "../../configs/supabase";
import theme from "../../configs/theme";
import getProjectConfig from "../../configs/projectConfigs";

const Sidebar = ({ isSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const userDataa = useSelector((state) => state.appState.userData);
  const projectConfig = getProjectConfig();


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setOpen(false);
    signOut();
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      localStorage.clear();
      // dispatch(resetUserData()); cleare user data
      navigate("/logIn");
    } catch (e) {
      console.log(e.message);
    }
  };

  const toggleSidebar = () => {
    dispatch(setOpenDrawer(!isSidebarOpen));
    const event = new CustomEvent('sidebarToggle', { detail: { isOpen: !isSidebarOpen } });
    window.dispatchEvent(event);
  };

  // console.log(appRoutes);


  return (
    isSidebarOpen && (
      <Drawer open={isSidebarOpen} variant="permanent" sx={styles.drawer}>
        <List disablePadding sx={{ ...styles.list, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
            marginTop: "10px"
          }}>
            <Toolbar >
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent="center">
                <img
                  src={projectConfig?.logo}
                  alt="Separator"
                  style={{
                    width: "77px",
                    height: "55px",
                  }}
                />
              </Stack>
            </Toolbar>
            <TbIndentDecrease
              onClick={toggleSidebar}
              color="white"
              size="30px"
              style={{ marginRight: "25px", cursor: "pointer" }}
            />
          </div>

          <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
            <img
              style={styles.separatorImage}
              src={separator}
              alt="Separator"
            />
          </Stack>

          <div style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            height: "50px",
            marginLeft: "25px"
          }}>
            <ChartIcon />
            <Typography style={{
              fontWeight: "500",
              fontSize: "17px",
              lineHeight: "24px",
              color: theme.palette.text.whiteSmoke,
              marginLeft: "20px",
            }}>
              Dashboard
            </Typography>
          </div>

          <Box sx={{ ...styles.routeContainer, flexGrow: 1 }}>
            {appRoutes.map((route, index) => {
              return route.children ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              );
            })}
          </Box>

          <Box>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center">
              <img
                style={styles.separatorImage}
                src={separator}
                alt="Separator"
              />
            </Stack>
            <Box sx={styles.userContainer}>
              <img
                alt={userDataa?.name}
                style={styles.avatar}
                src={projectConfig.logoBg}
                data-no-gallery={true}
              />
              <div style={{ width: "15px" }} />
              <div>
                <Typography variant="body2" style={styles.typography}>
                  {userDataa?.user_name}
                </Typography>
                <Typography variant="body2" style={{
                  color: theme.palette.basic.white,
                  lineHeight: "27px",
                }}>
                  Admin
                </Typography>
              </div>
              <div style={styles.logoutIconContainer} onClick={handleClickOpen}>
                <IoLogOut size={30} />
              </div>
            </Box>
          </Box>
        </List>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              borderRadius: '8px',
              width: '400px',
              maxWidth: '400px',
              height: '150px',
              overflowY: 'auto',
              overflowX: 'hidden',
              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)',
              padding: "0px 10px 10px 0px"
            }
          }}
        >
          <DialogTitle style={{ fontWeight: 'bold', paddingBottom: '8px' }}>Confirmation Logout</DialogTitle>
          <DialogContent style={{ paddingBottom: '16px', fontWeight: '500' }}>
            Are you sure you want to logout?
          </DialogContent>
          <DialogActions >
            <WhiteButton sx={{
              width: "80px",
              height: "40px",
            }} onClick={handleClose} >
              Cancel
            </WhiteButton>
            <BlueButton
              sx={{
                width: "80px",
                height: "40px",
              }}
              onClick={handleAgree}
            >
              Logout
            </BlueButton>
          </DialogActions>
        </Dialog>
      </Drawer>
    )
  );


};

const styles = {
  drawer: {
    width: sizeConfigs.sidebar.width,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: sizeConfigs.sidebar.width,
      boxSizing: "border-box",
      borderRight: 0,
      backgroundColor: theme.palette.primary.sidebar,
      color: theme.palette.text.lightGray,
    },
  },
  list: {
    overflowY: 'auto',
    height: '100%',
    scrollbarWidth: 'none', /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none', /* Chrome, Safari, Opera */
    },
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  toggleIcon: {
    marginTop: "40px",
    marginRight: "15px",
    zIndex: 3
  },
  toolbar: {
    marginBottom: "10px",
    marginTop: '-30px'
  },
  logo: {
    width: "130px",
  },
  separatorImage: {
    marginTop: 10,
  },
  routeContainer: {
    flexGrow: 1,
  },
  modalOkButton: {
    backgroundColor: theme.palette.primary.sidebar,
    color: theme.palette.basic.white,
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "45px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  avatar: {
    height: "45px",
    width: "45px",
    borderRadius: '50%'
  },
  typography: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",

  },
  logoutIconContainer: {
    marginLeft: "20px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
};

export default Sidebar;
