import {
    Box, Button, Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import theme from '../../configs/theme';
import InputField from "../../components/InputField"
import { FiSearch } from "react-icons/fi";
import DynamicTable from "../../components/common/table/DynamicTable";
import { useState } from "react";
import { Switch } from "antd";
import { LuEye } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import Status from "../../components/common/status/status";
import AddNewSubscription from './AddNewSubscriptionType';
import EditSubscription from './EditSubscription';
import AddButton from '../../components/common/Buttons/AddButton';
import axios from 'axios';
import { API_URL } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setSubTypes, setSubs } from "../../redux/features/appStateSlice";
import deleteSubescription from "../../assets/images/deleteSubscription.png";
import approveSub from "../../assets/images/approveSub.png";
import { message } from "antd";
import BlueButton from '../../components/common/Buttons/BlueButton';
import WhiteButton from '../../components/common/Buttons/WhiteButton';
import DeleteItemDialog from '../../components/common/DeleteItem';
import DynamicFilter from '../../components/common/filter/DynamicFilter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const hoverEffectStyle = {
    cursor: "pointer",
    borderRadius: 25,
    height: '60px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'scale(1.2)',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    }
};


const style = {
    color: "#172B4D",
    fontSize: "14.5px",
    fontWeight: "600",
    lineHeight: "20px",

};

function Subscription() {

    const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || "Subscription Types");
    const subscriptionTypes = useSelector((state) => state.appState.subTypes);
    const subs = useSelector((state) => state.appState.subs);
    const userData = useSelector(state => state?.appState?.userData);
    const [addNewSubscription, setAddNewSubscription] = useState(false);
    const [editSubscription, setEditSubscription] = useState(false);
    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState(subscriptionTypes);
    const [subscriptions, setSubscriptions] = useState(subs);
    const [subCount, setSubCount] = useState(0);
    const [typeCount, setTypeCount] = useState(0);
    const [deletedItemId, setDeletedItemId] = useState();
    const [approvedSubId, setApprovedSubId] = useState();
    const [dataToEdit, setDataToEdit] = useState();
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [openDeleteSub, setOpenDeleteSub] = useState(false);
    const [approveConfirmationDialogOpen, setApproveConfirmationDialogOpen] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [subscriptionStatus, setSubscriptionStatus] = useState("");

    const currentPage = useRef(1);
    const currentLimit = useRef(5);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const dispatch = useDispatch();
    const [action, setAction] = useState("add");
    const [updating, setUpdating] = useState(false);
    const [updatingRowId, setUpdatingRowId] = useState(null);

    const [filters, setFilters] = useState({
        paymentStatus: null,
        subscriptionStatus: null,
        renewDate: null,
        startDate: null,
        endDate: null,
        subscriptionType: null,
    });

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    useEffect(() => {
        localStorage.setItem('activeButton', activeButton);
    }, [activeButton]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
        handlePagenation()
    };

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setPage(1);
        setRowsPerPage(rows);
        handlePagenation()
    };

    const handlePagenation = () => {
        activeButton === "Subscription Types" ? getSubscriptiontypes() : getSubscriptions()
    }
    const handleActiveButton = (active) => {
        setActiveButton(active)
        setSearchWord("")
    }

    const handleOpenDialog = () => {
        setAddNewSubscription(true);
    };
    const handleOpenEditDialog = () => {
        setEditSubscription(true);
    };

    const handleCloseDialog = () => {
        setAddNewSubscription(false);
        setEditSubscription(false);
    };

    const getSubscriptiontypes = async () => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription-types?page=${currentPage.current}&limit=${currentLimit.current}&search_name=${searchWord}&sort_created_at=desc`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                const parsedData = JSON.parse(response?.data);
                const subbTypes = parsedData?.data?.data;
                setTypeCount(parsedData?.data?.count)
                dispatch(setSubTypes(subbTypes));
                setTypes(subbTypes);
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error Get data: ", error);
                setLoading(false);
            });
    }

    // &filter_subscription.name=Monthly    

    // &search_user.full_name=${searchWord}
    // &search_user.email=${searchWord}
    // &filter_payment_status=paid

    const getParams = () => {

        const params = {
            page: currentPage.current,
            limit: currentLimit.current,
            "search_user.full_name": searchWord,
            "search_user.email": searchWord,
            filter_payment_status: filters?.paymentStatus,
            filter_subscription_status: filters?.subscriptionStatus,
            date_start_date: filters?.startDate,
            date_end_date: filters?.endDate,
            date_renew_date: filters?.renewDate,
            "filter_subscription.id": filters?.subscriptionType,
            sort_created_at: "desc"
        };
        return params;
    };

    const getSubscriptions = async () => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription`,
            headers: {
                "Content-Type": "application/json",
            },
            params: getParams(),

        };
        axios
            .request(config)
            .then((response) => {
                const parsedData = JSON.parse(response?.data);
                const subs = parsedData?.data?.data;
                setSubCount(parsedData?.data?.count)
                dispatch(setSubs(subs));
                setSubscriptions(subs);

                setLoading(false);
            })
            .catch((error) => {
                console.log("Error Get data: ", error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getSubscriptiontypes()
        getSubscriptions()
    }, [])
    useEffect(() => {
        activeButton === "Subscription Types" ? getSubscriptiontypes() : getSubscriptions()

    }, [searchWord, filters])

    const handleEnableDisableChange = async (id, value) => {
        setUpdatingRowId(id);
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription-types/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { is_enabled: value }
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptiontypes()
                setUpdatingRowId(null);
            })
            .catch((error) => {
                console.log("Error update data: ", error);
                setUpdating(false);
            });
    }

    const DeleteSubscriptiontype = async (id) => {
        setLoading(true);
        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription-types/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptiontypes()
                setLoading(false);
                setDeleteConfirmationDialogOpen(false)
                message.success("The type was deleted successfully.");
            })
            .catch((error) => {
                console.log("Error delete data: ", error);
                setLoading(false);
            });
    }

    const deleteSubscription = async (id) => {
        setLoading(true);
        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptions()
                setLoading(false);
                setOpenDeleteSub(false)
                message.success("subscription was deleted successfully.");
            })
            .catch((error) => {
                console.log("Error delete data: ", error);
                message.error("Error delete data.");
                setLoading(false);
            });
    }

    const handleDeleteSubscription = () => {
        deleteSubscription(deletedItemId);
    };

    const handleDeleteConfirmation = () => {
        DeleteSubscriptiontype(deletedItemId);
    };

    const handleClearFilter = () => {
        handleFilterChange(null)
    };

    const handleFilterChange = (data) => {

        if (data === null) {
            setFilters({
                paymentStatus: null,
                subscriptionStatus: null,
                renewDate: null,
                startDate: null,
                endDate: null,
                subscriptionType: null,
            });
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                paymentStatus: data?.paymentStatus || prevFilters.paymentStatus,
                subscriptionStatus: data?.subscriptionStatus || prevFilters.subscriptionStatus,
                subscriptionType: data?.subscriptionType || prevFilters.subscriptionType,
                startDate: data?.startDate ? dayjs(data?.startDate).format('YYYY-MM-DD') : prevFilters.startDate,
                renewDate: data?.renewDate ? dayjs(data?.renewDate).format('YYYY-MM-DD') : prevFilters.renewDate,
                endDate: data?.endDate ? dayjs(data?.endDate).format('YYYY-MM-DD') : prevFilters.endDate
            }));
        }

    };

    const ApproveSubscription = async (id) => {
        setLoading(true);
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription/${id}/payment-status`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                performed_by: userData?.id,
                payment_status: "paid"
            },
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptions()
                setLoading(false);
                setApproveConfirmationDialogOpen(false)
                message.success("The request was approved successfully.");
            })
            .catch((error) => {
                console.log("Error : ", error);
                setLoading(false);
            });
    }

    const handleApproveConfirmation = () => {
        ApproveSubscription(approvedSubId);
    };

    const filterData = {

        'Payment Status': [
            {
                name: 'paymentStatus',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Paid', value: 'paid' },
                    { label: 'Unpaid', value: 'unpaid' },
                ],
                type: 'radio', // Displays as a horizontal checkbox
                direction: 'horizontal',
            },
        ],
        'Subscription Status': [
            {
                name: 'subscriptionStatus',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Active', value: 'active' },
                    { label: 'Pending', value: 'pending' },
                    { label: 'Renew', value: 'renew' },
                    { label: 'Canceled', value: 'canceled' },
                    { label: 'Expired', value: 'expired' },
                    { label: 'Free_trial', value: 'free_trial' },
                ],
                type: 'radio', // Displays as a horizontal checkbox
                direction: 'horizontal',
            },
        ],
        'Renew Date ': [
            {
                name: 'renewDate',
                gridWidth: { xs: 12, sm: 12 },
                type: 'date',
                placeholder: 'Select Date',
            },
        ],
        'Start - End Date': [
            {
                name: 'startDate',
                gridWidth: { xs: 12, sm: 6 },
                type: 'date',
                placeholder: 'Start Date',
            },
            {
                name: 'endDate',
                gridWidth: { xs: 12, sm: 6 },
                type: 'date',
                placeholder: 'End Date',
            },
        ],
        'Subscription Type': [
            {
                name: 'subscriptionType',
                gridWidth: { xs: 12, sm: 12 },
                list: types && types?.map(type => ({
                    label: type.name,
                    value: type.id
                })),
                disable: { condition: () => types && types?.length <= 0 },
                type: 'radio',
                direction: 'horizontal',
                placeholder: 'Select Type',
            },
        ]

    };
    const columns =
        activeButton === "Subscription Types" ?
            [
                {
                    field: "name",
                    headerName: "Name",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    editable: true,
                    align: "left",
                    style: style,
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "user_count",
                    headerName: "Users",
                    flex: 1,
                    minWidth: "50px",
                    sort: true,
                    editable: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "price",
                    headerName: "Price",
                    flex: 1,
                    minWidth: "75px",
                    sort: true,
                    editable: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "billing_frequency",
                    headerName: "Billing Frequency",
                    flex: 1,
                    minWidth: "180px",
                    sort: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "free_trial_days",
                    headerName: "Free Trial",
                    flex: 1,
                    minWidth: "110px",
                    sort: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "description",
                    headerName: "Description",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box>
                            {!params?.row?.description ? (
                                <span>_</span>
                            ) : (
                                <span
                                    style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        display: "inline-block",
                                    }}
                                >{params?.row?.description}</span>
                            )}
                        </Box>
                    ),
                },
                {
                    field: "is_enabled",
                    headerName: "Enable/Disable",
                    flex: 1,
                    minWidth: "100px",
                    style: style,
                    sort: true,
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Switch
                                style={{
                                    backgroundColor: params?.row?.is_enabled ? theme.palette.primary.main : "",
                                }}
                                checked={params.row.is_enabled}
                                onChange={(value) => {
                                    handleEnableDisableChange(params?.row?.id, value)
                                }}
                                loading={updatingRowId === params?.row?.id}
                                disabled={updatingRowId === params?.row?.id}
                            />
                        </div>
                    ),
                },
                {
                    field: "action",
                    headerName: "Action",
                    align: "center",
                    style: style,
                    minWidth: "100px",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <LuEye size="22.5px" style={{
                                color: "#16151C", cursor: "pointer",
                            }}
                                onClick={() => {
                                    setAction("view")
                                    setDataToEdit(params.row)
                                    handleOpenDialog()
                                }}
                            />
                            <CiEdit size="22.5px" style={{
                                color: "#16151C", cursor: "pointer",
                            }}
                                onClick={() => {
                                    setAction("edit")
                                    setDataToEdit(params?.row)
                                    handleOpenDialog()
                                }}
                            />
                            <RiDeleteBin5Line size="22.5px" style={{
                                color: "#16151C", cursor: "pointer",
                            }}
                                onClick={() => {
                                    setDeleteConfirmationDialogOpen(true)
                                    setDeletedItemId(params?.row?.id)
                                }}
                            />
                        </Box>
                    ),
                },
            ] : [
                {
                    field: "user.full_name",
                    headerName: "User",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    editable: true,
                    align: "left",
                    style: style,
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box>
                            {(params.row.user && params.row.user.full_name) ? (
                                <span>{params.row.user.full_name}</span>
                            ) : (
                                <span>_</span>
                            )}
                        </Box>
                    ),
                },
                {
                    field: "user.email",
                    headerName: "Email",
                    flex: 1,
                    minWidth: "100px",
                    sort: true,
                    editable: true,
                    style: style,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box>
                            {(params.row.user && params.row.user.email) ? (
                                <span style={{ textTransform: "none" }}>{params.row.user.email}</span>
                            ) : (
                                <span>_</span>
                            )}
                        </Box>
                    ),
                },
                {
                    field: "subscription.name",
                    headerName: "Subscription Type",
                    flex: 1,
                    minWidth: "180px",
                    style: style,
                    sort: true,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box>
                            <span>{params?.row?.subscription?.name}</span>
                        </Box>
                    ),
                },
                {
                    field: "start_date",
                    headerName: "Start Date",
                    flex: 1,
                    style: style,
                    minWidth: "120px",
                    sort: true,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "renew_date",
                    headerName: "Renew Date",
                    flex: 1,
                    style: style,
                    minWidth: "130px",
                    sort: true,
                    align: "left",
                    headerClassName: "super-app-theme--header",
                },
                {
                    field: "payment_status",
                    headerName: "Payment Status",
                    style: style,
                    flex: 1,
                    minWidth: "160px",
                    sort: true,
                    align: "center",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <div>
                            <Status status={params?.row?.payment_status} />
                        </div>
                    ),
                },
                {
                    field: "subscription_status",
                    headerName: "Subscription Status",
                    style: style,
                    flex: 1,
                    minWidth: "190px",
                    sort: true,
                    align: "center",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <div
                            style={{
                                cursor: (params?.row?.payment_status === "unpaid" ||
                                    ((params?.row?.payment_status === "paid") && (params?.row?.subscription_status === "renew"))
                                )
                                    ? "pointer" : ""
                            }}
                            onClick={() => {
                                if (params?.row?.payment_status === "unpaid" || ((params?.row?.payment_status === "paid") && (params?.row?.subscription_status === "renew"))
                                ) {
                                    setApproveConfirmationDialogOpen(true);
                                    setApprovedSubId(params?.row?.id)
                                    setSubscriptionStatus(params?.row?.subscription_status)
                                }
                            }}
                        ><Status status={params?.row?.subscription_status} />
                        </div>
                    ),
                },
                {
                    field: "action",
                    headerName: "Action",
                    style: style,
                    minWidth: "50px",
                    align: "left",
                    headerClassName: "super-app-theme--header",
                    renderCell: (params) => (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                gap: "8px"
                            }}
                        >
                            {((params.row.payment_status === "unpaid")) && (
                                <CiEdit size="22.5px" style={{
                                    color: "#16151C", cursor: "pointer", margin: "1px"
                                }}
                                    onClick={() => {
                                        setDataToEdit(params?.row)
                                        handleOpenEditDialog(true)
                                    }}

                                />
                            )}
                            <RiDeleteBin5Line size="22.5px" style={{
                                color: "#16151C", cursor: "pointer", margin: "1px"
                            }}
                                onClick={() => {
                                    setDeletedItemId(params?.row?.id)
                                    setOpenDeleteSub(true)
                                }}
                            />
                        </Box>
                    ),
                },
            ];


    return (
        <Box>
            <AddNewSubscription addNewSubscription={addNewSubscription} onClose={handleCloseDialog} getSubscriptiontypes={getSubscriptiontypes} action={action} dataToEdit={dataToEdit} />
            <EditSubscription editSubscription={editSubscription} onClose={handleCloseDialog} getSubscriptiontypes={getSubscriptiontypes} dataToEdit={dataToEdit} getSubscriptions={getSubscriptions} subscriptionTypes={subscriptionTypes} />

            {/* topside */}
            <Box>
                {/* title and add btn */}
                <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        marginTop: {
                            xs: "-101px",
                            sm: "-64px",
                            md: "-64px",
                            lg: "-64px",
                        }
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sm={5.8}
                        md={5.5}
                        lg={5.5}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "14px" }}>
                            <span
                                style={{
                                    color: "#FFFFFF",
                                    fontFamily: "TT Drugs Trial",
                                    fontSize: "24px",
                                    fontWeight: "700",
                                    lineHeight: "33px",
                                    marginLeft: "10px",
                                }}
                            >
                                {activeButton === "Subscription Types" ? "All Subscription Types" : "All Subscriptions"}
                            </span>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6.2}
                        md={6.5}
                        lg={6.5}
                        sx={{
                            display: "flex", justifyContent: {
                                xs: "start",
                                sm: "end",
                                md: "end",
                                lg: "end",
                            },
                            ml: {
                                xs: 1,
                                sm: 0,
                                md: 0,
                                lg: 0,
                            }
                        }}
                    >
                        <AddButton
                            sx={{
                                marginRight: "10px",
                            }}
                            onClick={() => {
                                setAction("add");
                                handleOpenDialog();
                            }}
                        >
                            + Add New Subscription Type
                        </AddButton>
                    </Grid>
                </Grid>
            </Box>
            {/* search and filter */}

            <Grid container
                style={{
                    paddingLeft: "10px",
                    marginTop: "10px",
                    marginLeft: "0",
                    // backgroundColor: theme.palette.basic.white,
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"

                }}>

                <Grid item xs={12} sm={7} md={6} display={"flex"} alignItems={"center"}
                >
                    <Box
                        spacing={1}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingLeft: "10px",
                            paddingTop: "18px",
                        }}>
                        <Box
                            style={{
                                border: `1.5px solid ${theme.palette.primary.main}`,
                                borderRadius: "8px",
                                marginTop: "2px",
                                marginBottom: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "7px",
                            }}
                            sx={{
                            }}>
                            <Box >
                                <Button
                                    variant={activeButton === "Subscription Types" ? "contained" : "text"}
                                    style={{
                                        color:
                                            activeButton === "Subscription Types"
                                                ? "white"
                                                : theme.palette.primary.main,
                                        backgroundColor:
                                            activeButton === "Subscription Types"
                                                ? theme.palette.primary.main
                                                : "transparent",
                                        fontWeight: "600",
                                        fontSize: activeButton === "Subscription Types" ? "16px" : "15px",
                                        textTransform: 'capitalize'
                                    }}
                                    onClick={() => handleActiveButton("Subscription Types")}>
                                    Subscription Types
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant={activeButton === "Subscription" ? "contained" : "text"}
                                    style={{
                                        color:
                                            activeButton === "Subscriptions"
                                                ? "white"
                                                : theme.palette.primary.main,
                                        backgroundColor:
                                            activeButton === "Subscriptions"
                                                ? theme.palette.primary.main
                                                : "transparent",
                                        fontWeight: "bold",
                                        fontSize: activeButton === "Subscriptionss" ? "16px" : "15px",
                                        whiteSpace: "normal",
                                        textTransform: 'capitalize'
                                    }}
                                    onClick={() => handleActiveButton("Subscriptions")}>
                                    Subscriptions
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={6} display={"flex"} gap={"9px"} alignItems={"center"} justifyContent={"end"} style={{ padding: "15px 10px 15px 0" }}>
                    <Grid item xs={12} md={activeButton === "Subscription Types" ? 5 : 6.5}  >
                        <InputField
                            id="Search"
                            placeholder={activeButton === "Subscription Types" ? "Search by Name." : "Search by User Name or Email."}
                            type="text"
                            value={searchWord}
                            onChange={(e) => setSearchWord(e.target.value)}
                            startAdornment={<FiSearch size={24} color="#88939D" />}
                            variant="outlined"
                            sx={{
                                fontWeight: "500",
                                fontSize: "17px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} style={{ maxWidth: "fit-content" }}>
                        {activeButton !== "Subscription Types" && (
                            <DynamicFilter
                                width={'450px'}
                                onApply={(data) => handleFilterChange(data)}
                                filterData={filterData}
                                mainColor={theme.palette.primary.main}
                                inputsStyle={{ borderRadius: '10px', height: '45px', fontSize: '16px' }}
                                onClear={handleClearFilter}
                            />
                        )}
                    </Grid>
                </Grid>

            </Grid>


            {activeButton === "Subscription Types" ? (
                <DynamicTable
                    columns={columns}
                    data={types}
                    count={typeCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    pageChange={handlePageChange}
                    rowsChange={handleRowsChange}
                    pagetype={"subscriptionTypes"}
                />
            ) : (
                <DynamicTable
                    columns={columns}
                    data={subscriptions}
                    count={subCount}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    pageChange={handlePageChange}
                    rowsChange={handleRowsChange}
                    pagetype={"subscriptions"}
                />
            )}

            {/* approve */}
            <Dialog
                style={{ zIndex: 10, borderRadius: "30px" }}
                open={approveConfirmationDialogOpen}
                onClose={() => setApproveConfirmationDialogOpen(false)}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={approveSub} alt="" />

                    </div>
                    <DialogContentText sx={{ textAlign: "center" }}>
                        <Typography component="span" variant="h6" style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
                            {(subscriptionStatus === "expired" || subscriptionStatus === "renew") ? "Renew Subscription" : "Approve Subscription"}
                        </Typography>
                    </DialogContentText>
                    <Typography component="span">
                        Are you sure you want to {(subscriptionStatus === "expired" || subscriptionStatus === "renew") ? "renew" : "approve"}
                        {" "}this subscription ?
                    </Typography>
                </DialogContent>


                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <WhiteButton
                        style={{
                            width: "50%",
                        }}
                        onClick={() => setApproveConfirmationDialogOpen(false)}
                        disabled={loading}
                    >
                        Cancel
                    </WhiteButton>
                    <BlueButton
                        style={{
                            width: "50%",
                        }}
                        onClick={() => handleApproveConfirmation()}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : (subscriptionStatus === "expired" || subscriptionStatus === "renew") ? "Yes, Renew" : "Yes, Approve"}
                    </BlueButton>
                </DialogActions>
            </Dialog>
            <DeleteItemDialog
                open={openDeleteSub}
                onClose={() => setOpenDeleteSub(false)}
                onDelete={handleDeleteSubscription}
                title={"Delete Subscription"}
                description={"Are you sure you want to delete this subscription ?"}
                loading={loading}
                deleteImage={deleteSubescription}
            />
            <DeleteItemDialog
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                onDelete={handleDeleteConfirmation}
                title={"Delete Subscription Type"}
                description={"Are you sure you want to delete this subscription Type ?"}
                loading={loading}
            />
        </Box>

    );
}

export default Subscription;
