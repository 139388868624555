// Purpose: To store constants that are used throughout the application.
let API_URL = "";
let SUPABASE_URL = "";
let SUPABASE_ANON_KEY = "";
let SHARE_URL = "";
let RESET_PASSWORD_URL = "";

if (process.env.REACT_APP_PROJECT_NAME === "goTaply") {

  if (process.env.REACT_APP_ENV === "production") {
    RESET_PASSWORD_URL = "https://dashboard.tapnet.me";
    API_URL = "https://ufmermvcwgjorzqlkznh.supabase.co/functions/v1";
    SHARE_URL = "https://app.tapnet.me/";
    SUPABASE_URL = "https://ufmermvcwgjorzqlkznh.supabase.co";
    SUPABASE_ANON_KEY =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVmbWVybXZjd2dqb3J6cWxrem5oIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ3MzY5MTEsImV4cCI6MjA0MDMxMjkxMX0.W2dvFalsjnFau0fB94Uiw8o9vVXEaVvQ4V4evkLeC7Q";
  } else {
    RESET_PASSWORD_URL = "https://tapnet-dashboard-dev.pages.dev";
    API_URL = "https://vqdplrtonbeybdjqwmqd.supabase.co/functions/v1";
    SHARE_URL = "https://tapnet-dev.pages.dev/";
    SUPABASE_URL = "https://vqdplrtonbeybdjqwmqd.supabase.co";
    SUPABASE_ANON_KEY =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZxZHBscnRvbmJleWJkanF3bXFkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA1Mjc4MzMsImV4cCI6MjAzNjEwMzgzM30.agMApyH6oZT-QvgMnWMBipLslggHwLEksROf68hubNs";
  }

} else {

  if (process.env.REACT_APP_ENV === "production") {
    RESET_PASSWORD_URL = "https://dashboard.tapnet.me";
    API_URL = "https://ufmermvcwgjorzqlkznh.supabase.co/functions/v1";
    SHARE_URL = "https://app.tapnet.me/";
    SUPABASE_URL = "https://ufmermvcwgjorzqlkznh.supabase.co";
    SUPABASE_ANON_KEY =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVmbWVybXZjd2dqb3J6cWxrem5oIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ3MzY5MTEsImV4cCI6MjA0MDMxMjkxMX0.W2dvFalsjnFau0fB94Uiw8o9vVXEaVvQ4V4evkLeC7Q";
  } else {
    RESET_PASSWORD_URL = "https://tapnet-dashboard-dev.pages.dev";
    API_URL = "https://vqdplrtonbeybdjqwmqd.supabase.co/functions/v1";
    SHARE_URL = "https://tapnet-dev.pages.dev/";
    SUPABASE_URL = "https://vqdplrtonbeybdjqwmqd.supabase.co";
    SUPABASE_ANON_KEY =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZxZHBscnRvbmJleWJkanF3bXFkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA1Mjc4MzMsImV4cCI6MjAzNjEwMzgzM30.agMApyH6oZT-QvgMnWMBipLslggHwLEksROf68hubNs";
  }

}


export { API_URL, SHARE_URL, SUPABASE_URL, SUPABASE_ANON_KEY, RESET_PASSWORD_URL };
