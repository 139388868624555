import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box, useTheme, Grid
} from '@mui/material';
import { message } from "antd";
import InputField from "../../components/InputField"
import { BsCurrencyDollar } from "react-icons/bs";
import { Switch } from "antd";
import BlueButton from '../../components/common/Buttons/BlueButton';
import WhiteButton from '../../components/common/Buttons/WhiteButton';
import axios from 'axios';
import { API_URL } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setSubTypes } from "../../redux/features/appStateSlice";
const AddNewSubscription = ({ addNewSubscription, onClose, getSubscriptiontypes, action, dataToEdit }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [freeTrial, setFreeTrial] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState(action === "edit" ? dataToEdit : {
        name: '',
        price: '',
        billing_frequency: 'Select Billing Frequency',
        free_trial_days: '',
        description: '',
        "is_enabled": true
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const theme = useTheme();



    useEffect(() => {
        action !== "add" ? setSubscriptionData(dataToEdit) : setSubscriptionData({
            name: '',
            price: '',
            billing_frequency: 'Select Billing Frequency',
            free_trial_days: '',
            description: '',
            "is_enabled": true
        })
        if (action !== "add" && dataToEdit?.free_trial_days > 0) {
            setFreeTrial(true)
        } else {
            setFreeTrial(false)
        }
    }, [addNewSubscription])

    const addSubscriptiontype = async (payload) => {
        setLoading(true);


        let config = {
            method: action === "edit" ? "put" : "post",
            maxBodyLength: Infinity,
            url: action === "edit" ? `${API_URL}/subscription-types/${dataToEdit?.id}` : `${API_URL}/subscription-types`,
            headers: {
                "Content-Type": "application/json",
            },
            data: payload
        };
        axios
            .request(config)
            .then((response) => {
                onClose();
                getSubscriptiontypes()
                setLoading(false);
                message.success(action === "edit" ? "The type was updated successfully." : "The type was added successfully.");


            })
            .catch((error) => {
                console.log("Error : ", error);
                setLoading(false);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubscriptionData({ ...subscriptionData, [name]: value });
        if (errors[name]) {
            setErrors({ ...errors, [name]: null });
        }
    };

    const handleClose = () => {
        setSubscriptionData({
            name: '',
            price: '',
            billing_frequency: 'Select Billing Frequency',
            free_trial_days: '',
            description: '',
            is_enabled: true
        });
        setErrors({})
        onClose();
    };

    const handleSwitchChange = (checked) => {
        setFreeTrial(checked);
    };

    const handleSave = () => {

        if (!validateFields()) {
            console.log("Validation Errors:", errors);
            return;
        }

        const payload = {
            name: subscriptionData.name.trim(),
            price: Number(subscriptionData.price),
            billing_frequency: subscriptionData.billing_frequency,
            free_trial_days: Number(subscriptionData.free_trial_days),
            description: subscriptionData.description,
            is_enabled: subscriptionData.is_enabled
        };

        addSubscriptiontype(payload)
    };

    const validateFields = () => {
        const newErrors = {};

        if (!subscriptionData.name.trim()) {
            newErrors.name = "Name is required";
        } else if (subscriptionData.name.length < 3 || subscriptionData.name.length > 20) {
            newErrors.name = "Name must be between 3 and 20 characters";
        }

        if (isNaN(subscriptionData.price) || subscriptionData.price <= 0) {
            newErrors.price = "Price should be greater than zero";
        } else if (!/^\d+(\.\d{1,2})?$/.test(subscriptionData.price)) {
            newErrors.price = "Price should be a decimal with up to two decimal places";
        }

        if (subscriptionData.billing_frequency === 'Select Billing Frequency') {
            newErrors.billing_frequency = "Please select a billing frequency";
        }

        if (freeTrial && (!subscriptionData.free_trial_days || subscriptionData.free_trial_days <= 0)) {
            newErrors.free_trial_days = "Please enter a valid number of free trial days should be greater than zero";
        }

        if (subscriptionData.description && subscriptionData.description.length > 500) {
            newErrors.description = "Description must not exceed 500 characters";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <Dialog
            open={addNewSubscription}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    padding: "5px",
                    width: { xs: "90%", sm: "70%", md: "50%" },
                    margin: { xs: "10px", sm: "auto" },
                }
            }}
        >
            <DialogTitle
                sx={{
                    color: "#172B4D",
                    fontWeight: "700",
                    fontSize: { xs: "16px", sm: "18px", md: "20px" },
                    lineHeight: "27px",
                }}
            >
                {action === "edit" ? "Edit Subscription Type " : action === "view" ? "Subscription Type" : "Add New Subscription Type"}
            </DialogTitle>
            <DialogContent>
                <InputField
                    id="name"
                    label="Name"
                    value={subscriptionData?.name}
                    placeholder='Please enter subscription name.'
                    onChange={handleChange}
                    required
                    disabled={action === "view"}
                    marginTop="10px"
                    sx={{
                        fontWeight: "500",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: "25px",
                        width: { xs: "100%" },
                    }}
                />
                {errors.name && (
                    <Typography sx={{
                        fontSize: "13px",
                        color: theme.palette.status.error,
                        fontWeight: "400",
                        ml: "15px"
                    }}>
                        {errors.name}
                    </Typography>
                )}
                <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: "20px" }}
                >
                    <Grid item xs={12} sm={12} md={6.8}>
                        <Box>
                            <InputField
                                id="price"
                                label="Price"
                                value={subscriptionData?.price}
                                placeholder='Please enter subscription price.'
                                disabled={action === "view"}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!isNaN(value) && Number(value) >= 0) {
                                        handleChange(e);
                                    }
                                }}
                                required
                                startAdornment={<BsCurrencyDollar size={17} color="#172B4D" />}
                                sx={{
                                    fontWeight: "500",
                                    fontSize: { xs: "14px", sm: "16px" },
                                    width: "100%",
                                    lineHeight: "25px",
                                    '& .MuiInputBase-root': {
                                        paddingRight: '10px',
                                    },
                                }}
                            />
                            {errors.price && (
                                <Typography sx={{
                                    fontSize: "13px",
                                    color: theme.palette.status.error,
                                    fontWeight: "400",
                                    ml: "15px"
                                }}>
                                    {errors.price}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5.2}>
                        <FormControl
                            sx={{
                                minWidth: "100%",
                                marginTop: { xs: "10px", sm: "1px" },
                            }}
                        >
                            <InputLabel
                                required
                                sx={{
                                    color: "#172B4D",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    "&.Mui-focused": {
                                        color: "#172B4D",
                                    },
                                }}
                            >
                                Billing Frequency
                            </InputLabel>
                            <Select
                                name="billing_frequency"
                                label="Billing Frequency"
                                value={subscriptionData?.billing_frequency}
                                onChange={handleChange}
                                onOpen={() => setIsMenuOpen(true)}
                                onClose={() => setIsMenuOpen(false)}
                                required
                                disabled={action === "view"}
                                sx={{
                                    width: '100%',
                                    height: "52px",
                                    fontSize: "16px",
                                    color: subscriptionData.billing_frequency === "Select Billing Frequency" ? "#7A869A" : "",
                                    fontWeight: "500",
                                    lineHeight: "25px",
                                    borderRadius: "8px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#88939D",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#88939D",
                                    },
                                }}
                            >
                                {!isMenuOpen && (
                                    <MenuItem value="Select Billing Frequency" disabled>
                                        Select Billing Frequency
                                    </MenuItem>
                                )}
                                <MenuItem value="Yearly">Yearly</MenuItem>
                                {/* <MenuItem value="Quarterly">Quarterly</MenuItem> */}
                                <MenuItem value="Monthly">Monthly</MenuItem>
                                <MenuItem value="Weekly">Weekly</MenuItem>
                                {/* <MenuItem value="OneTime">one-Time</MenuItem> */}
                            </Select>
                        </FormControl>
                        {errors.billing_frequency && (
                            <Typography sx={{
                                fontSize: "13px",
                                color: theme.palette.status.error,
                                fontWeight: "400",
                                ml: "15px"
                            }}>
                                {errors.billing_frequency}
                            </Typography>
                        )}
                    </Grid>
                </Grid>


                {action !== "view" && (
                    <Box sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        marginTop: "15px",
                        marginBottom: "15px",
                    }}>
                        <Switch
                            checked={freeTrial}
                            onChange={handleSwitchChange}
                            style={{
                                backgroundColor: freeTrial ? theme.palette.primary.main : "",
                            }}
                        />
                        <Typography sx={{
                            fontSize: "16px",
                            color: "#172B4D",
                            fontWeight: "600",
                            marginLeft: "10px",
                        }}>
                            Free Trial
                        </Typography>
                    </Box>
                )

                }

                {freeTrial && (
                    <>

                        <InputField
                            id="free_trial_days"
                            label="Number of Free Trial Days"
                            value={subscriptionData?.free_trial_days}
                            placeholder='Please enter number of free trial days.'
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!isNaN(value) && Number(value) >= 0 && Number.isInteger(Number(value))) {
                                    handleChange(e);
                                }
                            }}
                            required
                            disabled={action === "view"}
                            sx={{
                                marginTop: action === "view" ? "20px" : "5px",
                                fontWeight: "500",
                                fontSize: { xs: "14px", sm: "16px" },
                                lineHeight: "25px",
                                width: { xs: "100%", sm: "100%" },
                            }}
                        />
                        {errors.free_trial_days && (
                            <Typography sx={{
                                fontSize: "13px",
                                color: theme.palette.status.error,
                                fontWeight: "400",
                                ml: "15px"
                            }}>
                                {errors.free_trial_days}
                            </Typography>
                        )}
                    </>

                )}
                {action === "view" && subscriptionData?.description === '' ? ("") : (
                    <InputField
                        id="description"
                        label="Description"
                        disabled={action === "view"}
                        value={subscriptionData?.description}
                        placeholder='Please enter subscription description.'
                        onChange={handleChange}
                        multiline
                        rows={4}
                        sx={{
                            fontWeight: "500",
                            fontSize: { xs: "14px", sm: "16px" },
                            lineHeight: "25px",
                            marginTop: "20px",
                        }}
                    />
                )}


            </DialogContent>
            <DialogActions>
                <Box sx={{
                    display: "flex",
                    justifyContent: action === "view" ? "end" : "space-between",
                    width: { xs: "100%", sm: "70%", md: "50%" },
                    marginRight: "15px",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: "10px", sm: "0" },
                    marginBottom: "5px"
                }}>
                    <WhiteButton
                        onClick={handleClose}
                    >
                        {action === "view" ? "Close" : "Cancel"}
                    </WhiteButton>
                    {action !== "view" && (
                        <BlueButton
                            onClick={handleSave}
                        >

                            {loading ? "Loading..." : action === "edit" ? "save" : "Add"}
                        </BlueButton>
                    )

                    }

                </Box>
            </DialogActions>
        </Dialog >
    );
};

export default AddNewSubscription;
