import React, { useState } from "react";
import {
    Box,
    IconButton,
    Link,
    Typography,
    Checkbox,
    FormControlLabel,
    useTheme,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import supabase from "../../configs/supabase";
import { message } from "antd";
import { useDispatch } from "react-redux";
import InputField from "../../components/InputField"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ForgetPassword from "./ForgetPassword";
import BlueButton from "../../components/common/Buttons/BlueButton";
import { setUserData } from "../../redux/features/appStateSlice"
import getProjectConfig from "../../configs/projectConfigs";

const LogIn = () => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [forgetPassword, setForgetPassword] = useState(false);
    const [loginError, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rememberMe, setRememberMe] = useState(false);
    const projectConfig = getProjectConfig();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    };

    const fetchUserData = async (userId) => {
        const { data, error } = await supabase
            .from("users")
            .select("*")
            .eq("id", userId)
            .single();

        if (error) {
            setError("Account setup is incomplete. Please contact support.");
            return null;
        }

        return data;
    };

    const handleLogin = async (values) => {
        setError("");
        setLoading(true);
        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: values?.email,
                password: values?.password,
            });
            if (error) {
                handleSupabaseError(error);
                setLoading(false);
                return;
            }
            if (data?.user) {

                if (data?.user?.user_metadata?.user_type !== "admin") {
                    handleSupabaseError("not authorized");
                    setLoading(false);
                    return;
                } else {
                    const userData = await fetchUserData(data.user.id);
                    // console.log(userData);

                    if (userData) {
                        dispatch(setUserData(userData));

                        if (rememberMe) {
                            localStorage.setItem("isAuth", true);
                        } else {
                            sessionStorage.setItem("isAuth", true);
                        }

                        message.success("Login successfully.");
                        navigate("/subscription");
                    }
                }
                setLoading(false);
            }

        } catch (error) {
            setError("An unexpected error occurred. Please try again.");
            setLoading(false);
        }

    };

    const handleSupabaseError = (error) => {
        if (error === "not authorized") {
            setError("This email address is not authorized for admin login.");
        } else if (error.message.includes("Invalid login credentials")) {
            setError("Incorrect password or email.");
        } else if (error.message.includes("Email not confirmed")) {
            setError("Please confirm your email then try again.");
        } else {
            setError("An unexpected error occurred. Please try again.");
        }
        console.log("Supabase error:", error.message);
    };


    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is required"),
        password: Yup.string().required("Password is required"),
    });

    const handleForgetPassword = () => {
        setForgetPassword(!forgetPassword);
    };



    return (
        forgetPassword === true ? (<ForgetPassword onToggleLogin={handleForgetPassword} />) : (
            <Box
                sx={{
                    display: "flex",
                    alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "normal",
                    },
                    justifyContent: "center",
                    height: "100vh",
                    gap: "50px"
                }}
            >
                {/* Left Side - Image */}
                <Box
                    sx={{
                        flex: 1,
                        display: {
                            xs: "none",
                            sm: "none",
                            md: "flex",
                        },
                        justifyContent: "end",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={projectConfig?.loginImg}
                        alt="App Showcase"
                        style={{
                            width: "94%",
                            // maxWidth: "470px",
                            height: "95%",
                            objectFit: "cover",
                            borderRadius: "20px"

                        }}
                    />
                </Box>

                {/* Right Side - Login Form */}
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: {
                            xs: "center",
                            sm: "center",
                            md: "start",
                        },
                        justifyContent: "center",
                        padding: "3rem",
                    }}
                >
                    <Box sx={{ mb: 4 }}>
                        <img src={projectConfig.logoBg} alt="logo" style={{ width: "65px", height: "65px" }} />
                        <Typography variant="h5"
                            sx={{
                                mt: 2,
                                fontWeight: "800",
                                fontSize: "1.875rem",
                                lineHeight: "2.5rem",
                                color: theme.palette.text.lightBlack
                            }}>
                            Welcome 👋
                        </Typography>
                        <Typography variant="body1" color={theme.palette.text.grayLavender} sx={{
                            fontWeight: "600",
                        }}>
                            Please login here
                        </Typography>
                    </Box>

                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleLogin(values)}
                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form
                                style={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1.5rem",
                                }}
                            >
                                {/* Email Field */}
                                <InputField
                                    id="email"
                                    label="Email"
                                    value={values?.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Email Address"
                                    error={touched?.email && Boolean(errors?.email)}
                                    helperText={touched?.email && errors?.email}
                                    required
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        "& .MuiFormLabel-root": {
                                            fontWeight: "600",
                                            color: theme.palette.primary.main
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: theme.palette.primary.main,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: theme.palette.primary.main,
                                        },

                                    }}
                                />

                                {/* Password Field with Toggle Visibility */}
                                <InputField
                                    id="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={values?.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched?.password && Boolean(errors?.password)}
                                    helperText={touched?.password && errors?.password}
                                    required
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        "& .MuiFormLabel-root": {
                                            fontWeight: "600",
                                            color: theme.palette.primary.main
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: theme.palette.primary.main,
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: theme.palette.primary.main,
                                        },

                                    }}
                                    Adornment={
                                        <IconButton onClick={handleClickShowPassword} edge="end" sx={{ mr: 1 }}>
                                            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                        </IconButton>
                                    }
                                />

                                {/* Remember Me and Forgot Password Section */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rememberMe}
                                                onChange={handleRememberMeChange}
                                            />
                                        }
                                        label="Remember Me"
                                        sx={{
                                            "& .MuiTypography-root": {
                                                fontWeight: "300",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                            },

                                        }}
                                    />
                                    <Link
                                        variant="body2"
                                        onClick={() => handleForgetPassword(true)}
                                        sx={{
                                            cursor: "pointer",
                                            fontWeight: "300",
                                            lineHeight: "1.375rem",
                                            color: theme.palette.primary.main,
                                            textDecoration: "none"
                                        }}
                                    >
                                        Forgot Password?
                                    </Link>
                                </Box>

                                {/* Display login error if any */}
                                {loginError && (
                                    <Typography variant="body2" color={theme.palette.status.error} style={{
                                        fontWeight: 600
                                    }}>
                                        {loginError}
                                    </Typography>
                                )}

                                {/* Submit Button */}
                                <BlueButton
                                    type="submit"
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        width: "100%",
                                        backgroundColor: theme.palette.primary.main
                                    }}
                                >
                                    {loading ? "Logging in..." : "Login"}
                                </BlueButton>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>

        )
    );
};

export default LogIn;
