import { useEffect } from 'react';

const Metadata = ({ title, logo, og }) => {
    useEffect(() => {
        if (title) document.title = title;

        const icon = document.querySelector('link[rel="icon"]');
        if (icon && logo) icon.href = logo;

        if (og) {
            const metaTags = [
                { property: 'og:title', content: og.title },
                { property: 'og:description', content: og.description },
                { property: 'og:image', content: og.image },
                { property: 'og:type', content: og.type },
            ];

            metaTags.forEach(({ property, content }) => {
                let metaTag = document.querySelector(`meta[property="${property}"]`);
                if (!metaTag) {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', property);
                    document.head.appendChild(metaTag);
                }
                metaTag.setAttribute('content', content);
            });
        }
    }, [title, logo, og]);

    return null;
};

export default Metadata;
