import tapnetTabIcon from '../assets/images/logo.ico';
import tapnetLogo from '../assets/images/tapnet/logo/logo.png';
import tapnetLogoBb from '../assets/images/tapnet/logo/logoBg.png';
import tapnetLoginImg from "../assets/images/tapnet/loginImg.png"
import tapnetLoadingImg from "../assets/images/tapnet/loadingImg.png"
import tapnetTopbarImg from "../assets/images/tapnet/BgImg.png"

import goTaplyTabIcon from '../assets/images/logo.ico';
import goTaplyLogo from '../assets/images/goTaply/logo/lookCard.png';
import goTaplyLogoBg from '../assets/images/goTaply/logo/lookCard.png';
import goTaplyLoginImg from "../assets/images/goTaply/loginImg.png"
import goTaplyLoadingImg from "../assets/images/goTaply/loadingImg.png"
import goTaplyTopbarImg from "../assets/images/goTaply/BgImg.png"

const projectConfig = {
    goTaply: {
        tabIcon: goTaplyTabIcon,
        logo: goTaplyLogo,
        logoBg: goTaplyLogoBg,
        title: 'GoTaply Dashboard',
        loginImg: goTaplyLoginImg,
        topbarBgImg: goTaplyTopbarImg,
        LoadingImg: goTaplyLoadingImg,
        activeColor: '#0FCB5F',
        darkActiveColor: '#0CA24C', // for hover
        sidebarColor: '#0FCB5F',
        og: {
            title: 'GoTaply | Create your GoTaply Profile today',
            description: 'Create your GoTaply profile here. Start networking in seconds. The fastest way to share your contact info.',
            image: goTaplyLogoBg,
            type: 'website',
        },
    },
    tapnet: {
        tabIcon: tapnetTabIcon,
        logo: tapnetLogo,
        logoBg: tapnetLogoBb,
        title: 'Tapnet Dashboard',
        loginImg: tapnetLoginImg,
        topbarBgImg: tapnetTopbarImg,
        LoadingImg: tapnetLoadingImg,
        activeColor: '#0F5FCB',
        darkActiveColor: '#0D52B2', // for hover
        sidebarColor: '#05172E',
        og: {
            title: 'Tapnet | Create your Tapnet Profile today',
            description: 'Create your Tapnet profile here. Start networking in seconds. The fastest way to share your contact info.',
            image: tapnetLogoBb,
            type: 'website',
        },
    },
};

const defaultConfig = {
    tabIcon: tapnetTabIcon,
    logo: tapnetLogo,
    logoBg: tapnetLogoBb,
    title: 'Tapnet Dashboard',
    loginImg: tapnetLoginImg,
    topbarBgImg: tapnetTopbarImg,
    LoadingImg: tapnetLoadingImg,
    activeColor: '#0F5FCB',
    darkActiveColor: '#0D52B2', // for hover
    sidebarColor: '#05172E',
    og: {
        title: 'Tapnet | Create your Tapnet Profile today',
        description: 'Create your Tapnet profile here. Start networking in seconds. The fastest way to share your contact info.',
        image: tapnetLogoBb,
        type: 'website',
    },
};

const projectKey = process.env.REACT_APP_PROJECT_NAME || 'Default';


/**
 * Function to get the current configuration
 * @returns {object} The configuration for the current project
 */
const getProjectConfig = () => {
    return projectConfig[projectKey] || defaultConfig;
};

// Export the function
export default getProjectConfig;

