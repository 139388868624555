import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom"; // Import useLocation
import SortedArrows from "../../../assets/svg/SortedArrows"
import SortArrows from "../../../assets/svg/SortArrows"
import DropDown from "../DropDown";
import Users from "../../../assets/svg/icons/table/Users"
import Subscriptions from "../../../assets/svg/icons/table/Subscriptions"
import Cards from "../../../assets/svg/icons/table/Cards"
import theme from "../../../configs/theme";
import getProjectConfig from "../../../configs/projectConfigs";


const DynamicTable = ({
    columns,
    data,
    page,
    count,
    pageChange,
    rowsChange,
    pagetype,
    rowsPerPage
}) => {
    const location = useLocation();
    const [highlightedRowId, setHighlightedRowId] = useState(null); // State to track highlighted row
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'asc' });
    const [sortedData, setSortedData] = useState(data);
    const [sort, setSort] = useState(false);
    const projectConfig = getProjectConfig();
    useEffect(() => {
        if (location.state?.id) {
            setHighlightedRowId(location.state.id);

            setTimeout(() => {
                setHighlightedRowId(null);
            }, 2000);
        }
    }, [location.state]);


    const handleRowsChange = (e) => {
        rowsChange(e.target.value);
    };


    useEffect(() => {
        setSort(false);
    }, [data]);

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => {
            return acc && acc[part] !== undefined ? acc[part] : "";
        }, obj);
    };

    //ride rating
    const cardsCount = (row) => {
        return row?.cards[0]?.count;
    };

    const handleSort = (column) => {
        if (!column?.sort) return;

        const direction =
            sortConfig?.key === column?.field && sortConfig?.direction === "asc"
                ? "desc"
                : "asc";

        setSortConfig({ key: column?.field, direction });

        const isNestedField = column?.field?.includes('.');

        const sortedArray = [...data].sort((a, b) => {
            let aValue, bValue;

            if (column?.field === "cards[0].count") {
                aValue = cardsCount(a);
                bValue = cardsCount(b);
            } else {
                // default check if nested or not
                aValue = isNestedField ? getNestedValue(a, column?.field) : (a[column?.field] ?? "");
                bValue = isNestedField ? getNestedValue(b, column?.field) : (b[column?.field] ?? "");
            }

            //  string values
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return aValue.localeCompare(bValue, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                }) * (direction === "asc" ? 1 : -1);
            }

            //  numeric values
            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return (aValue - bValue) * (direction === "asc" ? 1 : -1);
            }

            //  date values
            if (aValue instanceof Date && bValue instanceof Date) {
                return (aValue - bValue) * (direction === "asc" ? 1 : -1);
            }

            // sorting for other types
            if (aValue < bValue) {
                return direction === "asc" ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === "asc" ? 1 : -1;
            }

            // If values are equal, return 0 (no change in order)
            return 0;



        });

        setSortedData(sortedArray);
        setSort(true);
    };




    const getPageMessage = () => {
        switch (pagetype) {
            case "users":
                return "It seems there are no users here yet.";
            case "cards":
                return "It seems there are no Cards here yet.";
            case "subscriptionTypes":
                return "It seems there are no Subscription Types here yet.";
            case "subscriptions":
                return "It seems there are no Subscriptions here yet.";
            default:
                return "No Updates Here";
        }
    };

    const getPageIcon = () => {
        switch (pagetype) {
            case "users":
                return "users";
            case "cards":
                return "cards";
            case "subscriptionTypes":
                return "subscriptionTypes";
            case "subscriptions":
                return "subscriptions";
            default:
                return projectConfig.logo;

        }
    };

    const renderIcon = () => {
        if (getPageIcon() === "users") {
            return <Users style={{
                marginTop: "10px",
                marginBottom: "20px"
            }} />;
        } else if (getPageIcon() === "subscriptions" || getPageIcon() === "subscriptionTypes") {
            return <Subscriptions style={{
                marginTop: "10px",
                marginBottom: "20px"
            }} />;
        } else if (getPageIcon() === "cards") {
            return <Cards style={{
                marginTop: "10px",
                marginBottom: "20px"
            }} />;
        } else {
            return (
                <img
                    style={{
                        color: theme.palette.primary.sidebar,
                        width: "60px",
                        height: "60px",
                        marginTop: "20px",
                    }}
                    src={projectConfig.logo}
                    alt={pagetype}
                />
            );
        }



    };

    return (
        <Box
            sx={{
                width: "100%",
                "& .super-app-theme--header": {
                    backgroundColor: "rgb(220,220,220)",
                    boxShadow: "none",
                },
            }}
        >
            <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns?.map((column, index) => (
                                    <TableCell
                                        key={`header-${column.field}-${index}`}
                                        align={column?.align}
                                        style={{
                                            minWidth: column?.minWidth,
                                            backgroundColor: "#f5f5f5",
                                            border: "none",
                                            textAlign: `${column?.align || 'left'}`
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#172B4D",
                                                fontWeight: "700",
                                                fontSize: "15.5px",
                                                display: "flex",
                                                alignItems: "center",
                                                lineHeight: "24px",
                                                justifyContent: `${column?.align === 'left' ? 'start' : column?.align === 'center' ? 'center' : 'end' ?? 'start'}`,
                                            }}
                                        >
                                            {column?.headerName}
                                            {column?.sort && (
                                                <Box component="span" sx={{ ml: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleSort(column);
                                                    }}
                                                >
                                                    {!sort ? (
                                                        <SortArrows
                                                            style={{
                                                                fontSize: 18,
                                                            }}
                                                        />
                                                    ) : (
                                                        <SortedArrows
                                                            style={{
                                                                fontSize: 18,
                                                                transform: sortConfig?.direction === 'asc' ? "" : 'rotate(180deg)'
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {/* Apply background color to the entire row */}
                        <TableBody>
                            {data && data?.length > 0 ? (
                                (sort ? sortedData : data)?.map((row) =>
                                (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row._id || Math.random()}
                                        sx={{
                                            color: row._id === highlightedRowId ? '#FFF' : "inherit",
                                            backgroundColor:
                                                row._id === highlightedRowId ? theme.palette.primary.sidebar + 40 : "inherit",
                                        }}

                                    >
                                        {columns?.map((column, colIndex) => {
                                            return (
                                                <TableCell
                                                    style={{
                                                        ...column?.style,
                                                        minWidth: column?.minWidth,
                                                        border: "none",
                                                        textTransform:
                                                            column?.field !== "email" ? "capitalize" : "",
                                                    }}
                                                    key={`${row._id}-${column.field}-${colIndex}`}
                                                    align={column?.align}
                                                >
                                                    {column?.renderCell
                                                        ? column?.renderCell({ row })
                                                        : row[column?.field]}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        style={{
                                            textAlign: "center",
                                            padding: "8px",
                                            fontFamily: "poppins",
                                        }}
                                        colSpan={columns.length}
                                    >
                                        {renderIcon()}
                                        <Typography variant="body1" sx={{
                                            fontWeight: "600",
                                            fontSize: "16px",
                                            mb: .5
                                        }}>
                                            {getPageMessage()}
                                        </Typography>
                                        <Typography variant="body1" sx={{
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            color: "#495974",
                                            mb: .5

                                        }}>
                                            Don't worry—things will get busy soon!
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    style={{
                        height: "60px",
                        display: "flex",
                        padding: "0 0px",
                        gap: "12px",
                        backgroundColor: "#f5f5f554",
                    }}
                >
                    <Box
                        style={{
                            height: "60px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "12px",
                        }}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                paddingTop: "8px",
                                color: "#9A9EA5",
                                paddingLeft: "16px"
                            }}
                        >
                            Rows per page :{" "}
                        </Typography>
                        <DropDown
                            data={[5, 10, 25, 100]}
                            value={rowsPerPage}
                            onChange={handleRowsChange}
                            variant="standard"
                            style={{
                                width: "60px",
                                textAlign: "center",
                                borderBottom: "none",
                            }} // Center the text
                        />
                    </Box>
                    <Stack
                        style={{
                            marginTop: "12px",
                            display: "flex",
                            alignItems: "center",
                            margin: "auto",
                        }}
                    >
                        <Pagination
                            count={Math.ceil(count / rowsPerPage)}
                            page={page}
                            onChange={(event, newPage) => {
                                pageChange(event, newPage);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                        />
                    </Stack>
                </Box>
            </Paper>
        </Box>
    );
};

export default DynamicTable;
