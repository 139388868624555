import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundimg from "../../assets/svg/NotFound"
import getProjectConfig from '../../configs/projectConfigs';
import theme from '../../configs/theme';

const NotFound = () => {
    const projectConfig = getProjectConfig();
    return (
        <div style={styles.container}>

            <div style={styles.illustration}>
                <NotFoundimg />
            </div>
            <p style={styles.subtitle}>Oops! The page you're looking for doesn't exist.</p>
            <div style={styles.logo}>
                <img src={projectConfig.logoBg} alt="Not found illustration" style={{ width: '70px', height: '70px' }} />
            </div>
            <p style={styles.description}>
                It might have been moved or deleted. Double-check the URL or head back to the homepage.
            </p>
            <Link to="/subscription" style={styles.button}>
                Go Home
            </Link>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        color: '#333',
        padding: '20px',
    },
    title: {
        fontSize: '96px',
        fontWeight: 'bold',
        margin: '0',
    },
    subtitle: {
        fontSize: '24px',
        fontWeight: '500',
        marginTop: '10px',
    },
    description: {
        fontSize: '16px',
        color: '#666',
        maxWidth: '400px',
        margin: '20px 0',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        borderRadius: '5px',
        textDecoration: 'none',
        marginTop: '20px',
    },
};

export default NotFound;
