import {
    Alert,
    Box,
    Container,
    Snackbar,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import getProjectConfig from "../../configs/projectConfigs";

const MainLayout = () => {
    const theme = useTheme();
    const openDrawer = useSelector((state) => state.appState.openDrawer);
    const [isOpened, setIsOpened] = useState(openDrawer);
    const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const xlDown = useMediaQuery(theme.breakpoints.down("xl"));
    const location = useLocation();
    const projectConfig = getProjectConfig()
    const fullWidthPaths = [
        '/users', '/cards'
    ];
    const fullWidthPathsWithDynamicID = [
        // '/discounts/request/:id',

    ];

    const isFullWidth = fullWidthPaths.some((path) => path === location.pathname) ||
        fullWidthPathsWithDynamicID.some((path) => {
            const regexPath = new RegExp(`^${path.replace(/:\w+/g, '[\\w-]+')}$`);
            return regexPath.test(location.pathname);
        });


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const userDataa = useSelector((state) => state.appState.userData);
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };



    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };
    useEffect(() => {
        setIsOpened(openDrawer);
    }, [openDrawer]);


    return (
        <>
            <Container maxWidth={"xl"}>
                <div
                    style={{
                        minHeight: "100vh",
                        width: "100%",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.palette.primary.blueGrayBg
                    }}
                >

                    <div
                        style={{
                            position: "absolute",
                            top: "-5%",
                            width: "100%",
                            height: "32%",
                            background: xlDown
                                ? `url(${projectConfig.topbarBgImg}) -148px center / cover`
                                : `url(${projectConfig.topbarBgImg}) 200% bottom / contain`,
                        }}
                    >
                        <Topbar isFullWidth={isFullWidth} />

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingLeft: !isFullWidth || !isOpened ? (mdDown || !isOpened ? "0px" : "290px") : '280px',
                            }}
                        >
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    maxWidth: !isFullWidth ? (xsDown || smDown ? '100%' : '97%') : '97%',
                                    // minHeight: isFullWidth ? "66vh" : "74vh",
                                    backgroundColor: isFullWidth ? theme.palette.primary.blueGrayBg : theme.palette.basic.white,
                                    borderRadius: isFullWidth ? 5 : "8px",
                                    mb: 5,
                                    mt: isFullWidth ? 15 : 5.5,
                                    ml: !isOpened ? "" : isFullWidth ? -1 : -2,
                                }}
                            >
                                <Outlet />
                            </Box>
                        </div>
                    </div>
                </div>
                <Box sx={{ display: "flex" }}>
                    <Box
                        component="nav"
                        sx={{
                            width: sizeConfigs.sidebar.width,
                            flexShrink: 0,
                        }}
                    >
                        <Sidebar isSidebarOpen={isOpened} />
                    </Box>
                </Box>
            </Container>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default MainLayout;