import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import theme from "./configs/theme";
import MainLayout from './components/layout/MainLayout';
import { appRoutes } from "./routes/AppRoutes";
import LogIn from "./pages/logIn/LogIn";
import NotFound from "./pages/notFound/NotFound";
import CreateNewPassword from "./pages/logIn/CreateNewPassword";
import PageWrapper from "./components/layout/PageWrapper";
import ProtectedRoute from "./routes/ProtectedRoute";
import Metadata from "./components/MetaData";
import getProjectConfig from "./configs/projectConfigs";

function App() {
  const projectConfig = getProjectConfig();

  return (
    <ThemeProvider theme={theme}>
      <Metadata
        title={projectConfig?.title || 'Default App'}
        logo={projectConfig?.tabIcon || '/favicon.ico'}
        og={projectConfig?.og}
      />
      <Box sx={{ backgroundColor: theme.palette.primary.blueGrayBg, minHeight: "100vh" }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              {appRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <ProtectedRoute >
                      <PageWrapper state={route.state}>
                        {route.element}
                      </PageWrapper>
                    </ProtectedRoute>
                  }
                />
              ))}
            </Route>
            <Route path="/createNewPassword" element={
              <ProtectedRoute >
                <CreateNewPassword />
              </ProtectedRoute>
            } />
            <Route path="/login" element={
              <ProtectedRoute >
                <LogIn />
              </ProtectedRoute>
            } />
            <Route path="/no-page" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>


  );
}

export default App;
